import { Select } from 'antd';
import { UserAvatar } from '../../../UserAvatar';
import { DisplayName } from '../../../DisplayName';
import { friendlyUsername } from '../../../../services/friendlyUsername';
import { gql, useSuspenseQuery } from '@apollo/client';
import {
  GetStrategicAdminUsersDocument,
  TenantRoleType,
} from '../../../../generated/graphql';
import { t } from 'i18next';

interface Props {
  id?: string;
  value?: string | null;
  className?: string;
  onChange?: (userId: string | undefined) => void;
}

export const StrategicPlanAdminSelector = ({
  id,
  value,
  className,
  onChange,
}: Props) => {
  const { data } = useSuspenseQuery(GetStrategicAdminUsersDocument, {
    variables: { tenantRoleType: TenantRoleType.STRATEGIC_PLAN_ADMIN },
    fetchPolicy: 'cache-and-network',
  });

  const users = data?.usersByRoleType.users ?? [];

  return (
    <Select
      id={id}
      allowClear
      value={value}
      className={className}
      options={users.map((user) => ({
        value: user.id,
        label: (
          <div>
            <span className="mr--s">
              <UserAvatar user={user} size="small" />
            </span>

            {friendlyUsername(user)}
          </div>
        ),
        user,
      }))}
      onChange={(value) => {
        onChange?.(value);
      }}
      showSearch
      optionRender={(option) => {
        const user = option.data.user;
        return (
          <div className="flx">
            <span>
              <UserAvatar user={user} />
            </span>
            <div className="ml pr--s ellipsis">
              <DisplayName user={user} />
              <div className="font-size--sm txt--secondary ellipsis">
                {user.email}
              </div>
            </div>
          </div>
        );
      }}
      filterOption={(inputValue, option) => {
        if (!option) return false;
        return option.user.email
          .toLowerCase()
          .includes(inputValue.toLowerCase()) ||
          (option.user && option.user.name)
          ? option.user.name!.toLowerCase().includes(inputValue.toLowerCase())
          : false;
      }}
      // onSearch={(text) => setOptions(getPanelValue(text))}
      placeholder="Search user"
      notFoundContent={<div>{t('common.noMatch')}</div>}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const STRATEGIC_PLAN_ADMIN_SELECTOR = gql`
  query getStrategicAdminUsers(
    $tenantId: ID
    $tenantRoleType: TenantRoleType!
  ) {
    usersByRoleType(tenantId: $tenantId, roleType: $tenantRoleType) {
      users {
        id
        name
        email
        displayName
        archived
        initials
        domainId {
          itemId
          tenantId
        }
      }
    }
  }
`;
