import { useTranslation } from 'react-i18next';
import { useTeams } from '../../../../../hooks/useTeams';
import { Select } from 'antd';
import { stringSort } from '../../../../../services/stringSort';
import { usePermissionForTeams } from '../../../../../hooks/usePermissionForTeams';
import { Action, TeamResourceType } from '../../../../../generated/graphql';

interface Props {
  teamResourceType: TeamResourceType;
  action: Action;
  alreadySelectedTeams?: string[];
  value?: string;
  onChange?: (teamId: string) => void;
  className?: string;
  alreadySelectedText?: string;
  disabled?: boolean;
}

export const PermittedTeamSelectorForAction = ({
  teamResourceType,
  action,
  alreadySelectedTeams = [],
  value,
  onChange,
  className,
  alreadySelectedText,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const { data, loading: loadingPermissions } = usePermissionForTeams(
    teamResourceType,
    action
  );
  const { teams, loading: loadingTeams } = useTeams();

  const teamIdsWithPermission =
    data?.permittedToPerformActionForTeam.permittedResources.map(
      (pt) => pt.teamId
    ) ?? [];

  const teamsOptions = teams
    .filter((t) => teamIdsWithPermission.includes(t.id))
    .sort((a, b) => stringSort(a.name, b.name));

  return (
    <Select
      disabled={disabled}
      showSearch
      className={className}
      loading={loadingTeams || loadingPermissions}
      value={value}
      placeholder={t('common.searchTeamPlaceholder')}
      optionFilterProp="children"
      onChange={(value) => onChange?.(value)}
      filterOption={(input, option) =>
        option?.title?.toLowerCase().includes(input.toLowerCase()) ?? false
      }
      options={teamsOptions.map((team) => {
        const alreadySelected = alreadySelectedTeams.includes(team.id);

        return {
          label: (
            <div className="flx">
              <div className="ml pr--s ellipsis">{team.name}</div>
              {alreadySelected && (
                <span className="ml--auto txt--primary font-size--sm ">
                  {alreadySelectedText ??
                    t('PermittedTeamSelectorForAction.alreadySelected')}
                </span>
              )}
            </div>
          ),
          value: team.id,
          disabled: alreadySelected,
          title: team.name,
        };
      })}
    />
  );
};
