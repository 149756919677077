import { gql, useSuspenseQuery } from '@apollo/client';
import {
  DurationStatus,
  GetTopInitiativeReportDocument,
} from '../../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { InitiativeSectionGrid } from './InitiativeSectionGrid';
import { ToggleSection } from '../../../../team/overview/components/ToggleSection';
import { InitiativeSectionSkeleton } from './InitiativeSection.skeleton';
import { Radio } from 'antd';
import { Suspense, useState } from 'react';
import { Gigantish } from '../Gigantish/Gigantish';
import { InitiativeTable } from './InitiativeTable';
import {
  MilestoneOrActiveToggle,
  MilestoneOrActiveToggleEnum,
} from '../../../initiatives/initiativeDetails/milestoneOrActivityToggle/MilestoneOrActiveToggle';
import { GlobalMigFilter } from './createTopInitiativeButton/GlobalMigFilter';
import { GlobalInitiativesEmptyPlaceholder } from '../GlobalMigSection/components/GlobalInitiativesEmptyPlaceholder';

type OverviewType = 'tiles' | 'timeline';

export const InitiativeSection = () => {
  const { t } = useTranslation();
  const [milestonesOrActivities, setMilestoneOrActivities] =
    useState<MilestoneOrActiveToggleEnum>(
      MilestoneOrActiveToggleEnum.Milestones
    );
  const { data } = useSuspenseQuery(GetTopInitiativeReportDocument, {
    variables: { filter: { companyInitiatives: true } },
  });
  const initiatives = data.topInitiativeReport.initiatives ?? [];

  const [selectedMigFilter, setSelectedMigFilter] = useState('ALL_MIGS');

  const [overviewSectionSelection, setOverviewSectionSelection] =
    useState<OverviewType>(
      (localStorage.getItem('overviewSectionSelection') as OverviewType) ||
        'tiles'
    );

  const options = [
    { label: 'Tiles', value: 'tiles' },
    { label: 'Timeline', value: 'timeline' },
  ];

  const filteredInitiatives = initiatives.filter((i) => {
    if (i.initiative.metadata.status !== DurationStatus.ACTIVE) {
      return false;
    }

    if (selectedMigFilter !== 'ALL_MIGS') {
      return i.initiative.metadata.supportsMigs.some(
        (mig) => mig.id === selectedMigFilter
      );
    }

    return true;
  });

  return (
    <div>
      <h2>{t('InitiativeSection.companyInitiatives')}</h2>
      <div className="flx flx--ai-center mt">
        <div>
          <h5 className="txt--secondary mb--xs">
            {t('InitiativeSection.dataAs')}
          </h5>
          <MilestoneOrActiveToggle
            value={milestonesOrActivities}
            onChange={setMilestoneOrActivities}
            disabled={overviewSectionSelection === 'timeline'}
          />
        </div>
        {overviewSectionSelection === 'tiles' && (
          <div className="ml--xl">
            <h5 className="txt--secondary mb--xs">
              {t('InitiativeSection.supportsMig')}
            </h5>
            <GlobalMigFilter
              value={selectedMigFilter}
              onChange={(val) => {
                setSelectedMigFilter(val);
              }}
            />
          </div>
        )}
        <div className="ml--auto">
          <h5 className="txt--secondary mb--xs">
            {t('InitiativeSection.view')}
          </h5>
          <Radio.Group
            options={options}
            onChange={({ target: { value } }) => {
              setOverviewSectionSelection(value);
              localStorage.setItem('overviewSectionSelection', value);
            }}
            value={overviewSectionSelection}
            optionType="button"
          />
        </div>
      </div>

      {initiatives.length === 0 && <GlobalInitiativesEmptyPlaceholder />}

      {overviewSectionSelection === 'tiles' && (
        <>
          <InitiativeSectionGrid
            milestonesOrActivities={milestonesOrActivities}
            initiatives={filteredInitiatives}
          />
          <div className="flx flx--jc-center flx--column">
            <ToggleSection
              titleExpanded={t('InitiativeSection.showLess')}
              titleNotExpanded={t('InitiativeSection.showMore')}
              data-intercom-target="Expandable section to show completed and upcoming MIGs"
              disabled={false}
            >
              <div className="flx flx--jc-space-between mb">
                <h3>{t('InitiativeSection.completedAndUpcoming')}</h3>
              </div>

              <InitiativeTable
                milestonesOrActivities={milestonesOrActivities}
                initiatives={initiatives.filter(
                  (i) =>
                    i.initiative.metadata.status === DurationStatus.COMPLETED ||
                    i.initiative.metadata.status === DurationStatus.UPCOMING
                )}
              />
            </ToggleSection>
          </div>
        </>
      )}

      {overviewSectionSelection === 'timeline' && (
        <div className="mt--l">
          <Suspense fallback={<Gigantish.skeleton />}>
            <Gigantish />
          </Suspense>
        </div>
      )}
    </div>
  );
};

InitiativeSection.Skeleton = InitiativeSectionSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_INITIATIVE_LANDING_PAGE_REPORT = gql`
  query getTopInitiativeReport($tenantId: ID, $filter: TopInitiativeFilter) {
    topInitiativeReport(tenantId: $tenantId, filter: $filter) {
      initiatives {
        id
        companyInitiative
        accumulatedStats {
          milestones {
            upcoming
          }
        }
        initiative {
          metadata {
            supportsMigs {
              id
            }
          }
        }
        ...InitiativeSectionRow__TopInitiativeReport
        ...initiativeTable__TopInitiativeReport
      }
    }
  }
`;
