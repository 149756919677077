import { Modal, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { ExpandArrow } from '../../../../../../../components/ExpandArrow';
import { gql } from '@apollo/client';
import {
  MilestoneStatus,
  MilestoneTable__InitiativeDetailedReportResponseFragment,
  MilestoneTable__MilestoneReportFragment,
} from '../../../../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { WarningIcon } from '../../../../../../../icons/performanceIcons/WarningIcon';
import { Colors } from '../../../../../../componentLibrary/Colors';
import { CheckIcon } from '../../../../../../../icons/overviewIcons/CheckIcon';
import { UpcomingEventIcon } from '../../../../../../../icons/overviewIcons/UpcomingEventIcon';
import { MilestoneActivitiesTable } from './components/MilestoneActivitiesTable';
import { friendlyDate } from '../../../../../../../services/dateFormats';
import { MilestoneActions } from './components/milestoneActions/MilestoneActions';
import { useMilestoneModal } from './hooks/useMilestoneModal';
import { Suspense, useMemo } from 'react';
import { Btn } from '../../../../../../../components/Button';
import { MilestoneDetails } from './components/milestoneActions/components/MilestoneDetails';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from '../../../../../../../components/ErrorPage';
import { StatusTag } from '../../../../../../../components/StatusTag';
import { ManageMilestoneTeams } from './components/milestoneActions/components/ManageMilestoneTeams';
import { stringSort } from '../../../../../../../services/stringSort';
import { EditMilestoneModal } from './components/milestoneActions/components/EditMilestoneModal';
import { CreateSkaForTenantInitiativeDrawer } from '../createSprintKeyActivityForInitiative/CreateSkaForTenantInitiativeDrawer';
import { MarkMilestoneAsCompletedModal } from './components/markMilestoneAsCompletedModal/MarkMilestoneAsCompletedModal';
import { ReactivateMilestoneModal } from './components/reactivateMilestoneModal/ReactivateMilestoneModal';
import { ArchiveMilestoneModal } from './components/archiveMilestoneAModal/ArchiveMilestoneModal';

interface Props {
  report: MilestoneTable__InitiativeDetailedReportResponseFragment;
  initiativeId: string;
  filters: {
    status: {
      completed: boolean;
      overdue: boolean;
      upcoming: boolean;
    };
    title: string | null;
    initiativeId: string | null;
  };
}

const matchMilestoneByStatus = (
  status: MilestoneStatus,
  filterStatus: {
    completed: boolean;
    overdue: boolean;
    upcoming: boolean;
  }
) => {
  return filterStatus[status.toLowerCase() as keyof typeof filterStatus];
};

const matchMilestoneByName = (name: string, filterTitle: string | null) => {
  if (filterTitle == null || filterTitle === '') return true;
  return name.toLowerCase().includes(filterTitle.toLowerCase());
};

const matchMilestoneBySupportsInitiatives = (
  milestone: MilestoneTable__MilestoneReportFragment,
  filterInitiativeId: string | null
) => {
  if (filterInitiativeId == null || filterInitiativeId === '') return true;
  if (
    milestone.milestone.metadata.supports.some(
      (si) =>
        si.__typename === 'SupportedInitiative' &&
        si.item.id === filterInitiativeId
    )
  ) {
    return true;
  }
  return false;
};

export const MilestoneTable = ({ report, filters, initiativeId }: Props) => {
  const { t } = useTranslation();
  const modalHandler = useMilestoneModal();
  const milestoneReports = useMemo(() => {
    return [
      ...report.milestones,
      ...report.allSubInitiatives.flatMap((i) => i.milestones),
    ];
  }, [report]);

  const filteredMilestoneReports = milestoneReports.filter((m) => {
    if (!matchMilestoneByStatus(m.milestone.metadata.status, filters.status))
      return false;
    if (!matchMilestoneByName(m.milestone.name, filters.title)) return false;
    if (!matchMilestoneBySupportsInitiatives(m, filters.initiativeId))
      return false;
    return true;
  });

  const milestoneColumns: ColumnProps<MilestoneTable__MilestoneReportFragment>[] =
    [
      {
        title: t('common.deadline'),
        width: 120,
        key: 'deadlineAt',
        defaultSortOrder: 'ascend',
        sorter: (a, b) =>
          stringSort(a.milestone.deadlineAt, b.milestone.deadlineAt),
        dataIndex: ['milestone', 'deadlineAt'],
        render: (deadline) => {
          return <div className="text-c">{friendlyDate(deadline)}</div>;
        },
      },
      {
        title: t('MilestoneTable.statusColumn'),
        key: 'status',
        dataIndex: ['milestone', 'name'],
        render: (text, milestoneWithStats) => {
          return (
            <StatusTag
              status={milestoneWithStats.milestone.metadata.status}
              showIcon
            />
          );
        },
      },
      {
        title: t('MilestoneTable.milestoneColumn'),
        key: 'name',
        dataIndex: ['milestone', 'name'],
        render: (text, milestoneInfoWithStats) => {
          return (
            <div>
              <div>{text}</div>
              <div className="txt--secondary h5">
                {milestoneInfoWithStats.milestone.metadata.supports
                  .map(
                    (s) =>
                      s.__typename === 'SupportedInitiative' && s.item.tag.title
                  )
                  .join(', ')}
              </div>
            </div>
          );
        },
      },
      {
        title: (
          <CheckIcon style={{ fontSize: 15, color: Colors.Status.OK_GREEN }} />
        ),
        key: 'completed',
        width: 90,
        dataIndex: ['stats', 'completed'],
      },
      {
        title: (
          <WarningIcon
            style={{ fontSize: 15, color: Colors.Status.OVERDUE }}
            className="space--r"
          />
        ),
        key: 'overdue',
        dataIndex: ['stats', 'overdue'],
        width: 90,
      },
      {
        title: (
          <UpcomingEventIcon
            style={{ fontSize: 15, color: Colors.Status.FUTURE_PURPLE }}
            className="space--r"
          />
        ),
        key: 'upcoming',
        width: 90,
        dataIndex: ['stats', 'upcoming'],
      },
      {
        title: '',
        key: 'actions',
        width: 50,
        render: (_, milestoneReport) => {
          return (
            <MilestoneActions
              openModal={modalHandler.openModal}
              milestone={milestoneReport.milestone}
              initiativeId={initiativeId}
            />
          );
        },
      },
      Table.EXPAND_COLUMN,
    ];

  const sharedModalProps = {
    footer: null,
    destroyOnClose: true,
    onCancel: modalHandler.closeModal,
  };

  const activeMilestoneId = modalHandler.activeModal?.milestoneId;

  return (
    <div>
      <Modal
        {...sharedModalProps}
        open={modalHandler.activeModal?.type === 'manageTeams'}
      >
        {modalHandler.activeModal?.type === 'manageTeams' &&
          activeMilestoneId && (
            <Suspense
              fallback={
                <ManageMilestoneTeams.Skeleton
                  milestoneName={modalHandler.activeModal.milestoneName}
                />
              }
            >
              <ManageMilestoneTeams
                milestoneName={modalHandler.activeModal.milestoneName}
                milestoneId={activeMilestoneId}
              />
            </Suspense>
          )}
      </Modal>

      <CreateSkaForTenantInitiativeDrawer
        open={modalHandler.activeModal?.type === 'addKeyActivity'}
        onClose={modalHandler.closeModal}
        contributingTeams={
          modalHandler.activeModal?.type === 'addKeyActivity'
            ? modalHandler.activeModal.allowedTeams
            : []
        }
        milestone={
          modalHandler.activeModal?.type === 'addKeyActivity'
            ? {
                id: modalHandler.activeModal.milestoneId,
                domainId: modalHandler.activeModal.milestoneDomainId,
              }
            : undefined
        }
        initiativeId={initiativeId}
      />

      <EditMilestoneModal
        open={modalHandler.activeModal?.type === 'edit'}
        onClose={modalHandler.closeModal}
        milestoneId={activeMilestoneId}
      />

      <MarkMilestoneAsCompletedModal
        milestoneId={modalHandler.activeModal?.milestoneId}
        visible={modalHandler.activeModal?.type === 'markAsCompleted'}
        onClose={modalHandler.closeModal}
      />

      <ReactivateMilestoneModal
        milestoneId={modalHandler.activeModal?.milestoneId}
        visible={modalHandler.activeModal?.type === 'reactivate'}
        onClose={modalHandler.closeModal}
      />

      <ArchiveMilestoneModal
        milestoneId={modalHandler.activeModal?.milestoneId}
        visible={modalHandler.activeModal?.type === 'archive'}
        onClose={modalHandler.closeModal}
      />

      <Modal
        {...sharedModalProps}
        open={modalHandler.activeModal?.type === 'details'}
        title={'Milestone Details'}
        footer={
          activeMilestoneId && (
            <Btn
              type="primary"
              onClick={() => {
                modalHandler.openModal({
                  type: 'edit',
                  milestoneId: activeMilestoneId,
                });
              }}
            >
              Edit Milestone
            </Btn>
          )
        }
      >
        <Suspense fallback={<MilestoneDetails.Skeleton />}>
          {modalHandler.activeModal?.milestoneId && (
            <MilestoneDetails
              milestoneId={modalHandler.activeModal?.milestoneId}
            />
          )}
        </Suspense>
      </Modal>
      <Table
        columns={milestoneColumns}
        dataSource={filteredMilestoneReports}
        style={{ borderRadius: '6px' }}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (milestoneReport) => (
            <ErrorBoundary FallbackComponent={ErrorPage}>
              <Suspense
                fallback={
                  <MilestoneActivitiesTable.Skeleton
                    numberOfActivities={
                      milestoneReport.stats.completed +
                      milestoneReport.stats.overdue +
                      milestoneReport.stats.upcoming
                    }
                  />
                }
              >
                <MilestoneActivitiesTable
                  milestoneItemId={milestoneReport.milestone.domainId.itemId}
                />
              </Suspense>
            </ErrorBoundary>
          ),
          expandIcon: ({ expanded, onExpand, record }) => (
            <ExpandArrow
              onClick={(e) => onExpand(record, e)}
              expanded={expanded}
            />
          ),
        }}
        pagination={false}
        rowKey={(milestoneReport) => milestoneReport.milestone.id}
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_TABLE__MILESTONE_REPORT = gql`
  fragment MilestoneTable__MilestoneReport on MilestoneReport {
    stats {
      completed
      overdue
      upcoming
    }
    milestone {
      id
      deadlineAt
      name
      metadata {
        status
        supports {
          ... on SupportedInitiative {
            item {
              id
              name
              tag {
                title
              }
              domainId {
                itemId
                tenantId
              }
            }
          }
        }
      }
      domainId {
        itemId
        tenantId
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_TABLE__INITIATIVE_DETAILED_REPORT_RESPONSE = gql`
  fragment MilestoneTable__InitiativeDetailedReportResponse on InitiativeDetailedReportResponse {
    id
    milestones {
      ...MilestoneTable__MilestoneReport
    }
    allSubInitiatives {
      milestones {
        ...MilestoneTable__MilestoneReport
      }
    }
  }
`;
